<template>
	<div class="h-full">
		<div class="sl-border">
			<el-table v-loading="loadding" :data="pageDataSource.Result" border highlight-current-row>
				<el-table-column prop="Name" label="名称">
					<template slot-scope="scope">
						<el-col :span="23">
							<el-input v-model="scope.row.Name" size="mini" placeholder="名称" />
						</el-col>
					</template>
				</el-table-column>
				<el-table-column prop="addressType" label="地址类型">
					<template slot-scope="scope">
						<el-col :span="23">
							<el-select v-model="scope.row.AddressType" collapse-tags placeholder="请选择" :maxlength="200">
								<el-option v-for="item in scope.row.AddressTypeList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-col>
					</template>
				</el-table-column>
				<el-table-column prop="MemberProductCode" label="地址">
					<template slot-scope="scope">
						<el-col :span="23">
							<Distpicker :placeholders="scope.row"></Distpicker>
						</el-col>
					</template>
				</el-table-column>
				<el-table-column prop="AddressDetail" label="详细地址">
					<template slot-scope="scope">
						<el-col :span="23">
							<el-input v-model="scope.row.AddressDetail" size="mini" placeholder="详细地址" />
						</el-col>
					</template>
				</el-table-column>
				<el-table-column label="经纬度" width="260">
					<template slot-scope="scope">
						<el-col :span="10">
							<el-input v-model="scope.row.Longitude" size="mini" placeholder="经度" />
						</el-col>
						<el-col :span="10">
							<el-input v-model="scope.row.Latitude" size="mini" placeholder="纬度" />
						</el-col>
						<el-col :span="4">
							<el-button @click="locate(scope.row)">定位</el-button>
						</el-col>
					</template>
				</el-table-column>
				<el-table-column prop="ContactName" label="联系人">
					<template slot-scope="scope">
						<el-col :span="23">
							<el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
						</el-col>
					</template>
				</el-table-column>
				<el-table-column prop="ContactPhone" label="联系电话">
					<template slot-scope="scope">
						<el-col :span="23">
							<el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系人" />
						</el-col>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="remove(scope.row)" size="small" type="text">删除</el-button>
						<el-button @click="edit(scope.row)" size="small" type="text"
							v-if="!scope.row.IsAdd">编辑</el-button>
						<el-button @click="save(scope.row)" size="small" type="text"
							v-if="scope.row.IsAdd">保存</el-button>

					</template>
				</el-table-column>
			</el-table>
			<div class="page">
				<el-pagination @size-change="pageSizeChange" @current-change="currentChange"
					:current-page="queryParam.PageIndex" :page-sizes="[10, 30, 50, 100]"
					:page-size="queryParam.PageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="pageDataSource.TotalCount">
				</el-pagination>
				<el-button type="text" class="btn-refurbish" @click="refreshPage">
					刷新
				</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loadding: false,
				pageDataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					Params: {}
				},
			}
		},
		props: {
			pageUrl: {
				type: String,
				default: ''
			},
			relationId: {
				type: String,
				default: null
			},
		},
		watch: {
			relationId: function(newVal, oldVal) {
				if (newVal && newVal != oldVal) {
					this.initPage();
				}
			},
		},
		mounted() {
			this.initPage();
		},
		methods: {
			refreshPage() {
				this.initPage();
			},
			pageSizeChange(val) {
				this.queryParam.PageSize = val;
			},
			currentChange(val) {
				this.queryParam.PageIndex = val;
				this.initPage();
			},
			handleCurrentChange: function(val) {
				this.$emit("onSelectedRow", val);
			},
			initPage() {
				var _this = this;
				console.log(_this.relationId)
				if (!_this.relationId) {
					return;
				}
				var parmsData = {
					FieldLabel: '',
					FieldValue: _this.relationId,
					FieldName: "RelationId",
					FieldType: 5,
					FieldValueStart: "",
					FieldValueEnd: ""
				};
				_this.queryParam.Params.RelationId = parmsData;

				_this.$ajax.query(_this.pageUrl, "post", _this.queryParam, (result) => {

					_this.pageDataSource = result;

					if (_this.pageDataSource.Result) {
						var list = [];
						list.push({
							Key: 0,
							Value: "装货地"
						});
						list.push({
							Key: 5,
							Value: "卸货地"
						});
						list.push({
							Key: 10,
							Value: "装卸货地"
						});
						_this.pageDataSource.Result.forEach(item => {
							item.AddressTypeList = list;
						})
					}

				});
			},
			delete(row) {
				var _this = this;
				_this.$ajax.query("omsapi/addressinfo/edit", "post", row, (result) => {
					if (result.IsSuccess) {
						this.initPage();
					} else {
						_this.Utils.messageBox(result.OperationDesc, "error");
						return false;
					}
				});

			},
			remove(row) {
				if (row.Id) {
					var _this = this;
					_this.$ajax.query("omsapi/addressinfo/delete?id=" + row.Id, "get", {}, (result) => {
						if (result.IsSuccess) {
							this.initPage();
						} else {
							_this.Utils.messageBox(result.OperationDesc, "error");
							return false;
						}
					});
				} else {
					this.pageDataSource.Result.remove(row);
				}
			},
			add(data) {
				if (!this.pageDataSource.Result) {
					this.pageDataSource.Result = [];
				}
				this.pageDataSource.Result.push(data);
			},
			save(row) {
				var _this = this;
				_this.$ajax.query("omsapi/addressinfo/create", "post", row, (result) => {
					if (result.IsSuccess) {
						this.initPage();
					} else {
						_this.Utils.messageBox(result.OperationDesc, "error");
						return false;
					}
				});
			},
			locate(row){
				this.Utils.setLocation(row);
			}
		},
	}
</script>